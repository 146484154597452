<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.4697 8.03033C11.7626 8.32322 12.2374 8.32322 12.5303 8.03033C12.8232 7.73744 12.8232 7.26256 12.5303 6.96967L9.53033 3.96967C9.23744 3.67678 8.76256 3.67678 8.46967 3.96967L5.46967 6.96967C5.17678 7.26256 5.17678 7.73744 5.46967 8.03033C5.76256 8.32322 6.23744 8.32322 6.53033 8.03033L9 5.56066L11.4697 8.03033Z"
      fill="currentColor"
    />
    <path
      d="M6.53033 9.96967C6.23744 9.67678 5.76256 9.67678 5.46967 9.96967C5.17678 10.2626 5.17678 10.7374 5.46967 11.0303L8.46967 14.0303C8.76256 14.3232 9.23744 14.3232 9.53033 14.0303L12.5303 11.0303C12.8232 10.7374 12.8232 10.2626 12.5303 9.96967C12.2374 9.67678 11.7626 9.67678 11.4697 9.96967L9 12.4393L6.53033 9.96967Z"
      fill="currentColor"
    />
  </svg>
</template>
<script>
import { Vue } from "vue-class-component";

export default class ChevronUpDown extends Vue {}
</script>
